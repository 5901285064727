import React, { useContext } from 'react'
import HeaderContext from '@insight/toolkit-react/lib/Header/HeaderContext'
import Button from '@insight/toolkit-react/lib/Button/Button'
import Icon from '@insight/toolkit-react/lib/Icon/Icon'
import { t } from 'api'

export default function FlyoutButtonNew() {
  const { activeFlyout, setActiveFlyout, activeMegaMenu } =
    useContext(HeaderContext)

  const toggleFlyout = () => {
    setActiveFlyout(!activeFlyout)
  }

  const isOpen = activeFlyout && !activeMegaMenu

  if (activeMegaMenu) {
    toggleFlyout()
  }

  return (
    <span className="c-header-nav__item  c-header-nav__item--highlight">
      <Button
        aria-expanded={isOpen}
        aria-label={t('Open account menu')}
        className="c-header-account-menu__btn  c-header-account-menu__btn--logged-in  c-header-nav__link"
        color="none"
        onClick={toggleFlyout}
      >
        {isOpen ? <Icon icon="close" /> : <Icon icon="navicon" />}
        <span className="c-header-account-menu__btn-text">
          <p>{t('Account Tools')}</p>
        </span>
      </Button>
    </span>
  )
}

FlyoutButtonNew.propTypes = {}
