import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { t } from 'api';
import LoggedInBar from "../LoggedInBar/LoggedInBar";
import { getRequesterGroupDetails } from '../../api/us';
import { ModalContext, MODALS } from '../Modal';
import IAHeaderContext from "../../context/IAHeaderContext";
import usePermissions from '../../hooks/usePermissions'

export default function IAHeaderMiddle({
  isMobile,
  isCheckout,
  isCES,
}) {
  const { setActiveModal } = useContext(ModalContext);
  const { headerInfo: { isLoggedIn } } = useContext(IAHeaderContext);
  const { userRequiresApproval } = usePermissions();
  const hideRequesterPopup = sessionStorage.getItem('hideRequesterPopup');

  useEffect(() => {
    if (isLoggedIn && userRequiresApproval) {
      const ishideRequesterPopupInStorage = hideRequesterPopup === "true";
      getRequesterGroupDetails().then(data => {
        if (!ishideRequesterPopupInStorage && data?.requestorGroups.length == 0) {
          setActiveModal(MODALS.PURCHASE_REQ_APPROVAL_MODAL, {
            onConfirm: () => {
              sessionStorage.setItem('hideRequesterPopup', true);
            },
            title: t('Action Required: Access Needed for Checkout')
          });
        }
      })
    } else {
      hideRequesterPopup && sessionStorage.removeItem('hideRequesterPopup')
    }
  }, []);

  return (
    <div className="c-header__middle">
      <LoggedInBar
        isMobile={isMobile}
        isCheckout={isCheckout}
        isCES={isCES}
      />
    </div>
  );
}

IAHeaderMiddle.propTypes = {
  isCheckout: PropTypes.bool.isRequired,
  isCES: PropTypes.bool,
  isMobile: PropTypes.bool,
};

IAHeaderMiddle.defaultProps = {
  isCES: false,
};
