import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@insight/toolkit-react/lib/Button/Button'
import Modal from '@insight/toolkit-react/lib/Modal/Modal'
import { t } from 'api';
import { Icon } from '@insight/toolkit-react'

export default function PurchaseApprovalModal(props) {
    const { onClose, onConfirm, title } = props

    const onConfirmHandler = () => {
        onConfirm()
        onClose()
    }

    return (
        <Modal isOpen closeOnOutsideClick={false} closeOnEsc={false} onClose={onClose} size="tiny">
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <p>
                    <Icon icon="alert" type="error" className="c-requester-alert-icon" />
                    <span className='c-requester-modal-line1'>
                        {t('To proceed with your checkout, you must be added to the relevant requestor groups to secure the required permissions.')}
                    </span>
                </p>
                <div className='c-requester-modal-line2'>
                    {t('Please reach out to your account administrator or account executive for further assistance.')}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={onConfirmHandler}>
                    {t('I Understand')}
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

PurchaseApprovalModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}