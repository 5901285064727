import React from 'react'

import { t } from '@insight/toolkit-utils'
import { Button } from '@insight/toolkit-react'
import usePermissions from "../../hooks/usePermissions";

export default function Tools(props) {
  const { account, goToHashLink } = props
  const { enableRenewalsHub } = usePermissions()
  
  const toolsObject = [
    {
      title: account?.toolsObj?.manageCloud ? 'cloudManagementDashboard' : '',
      onClick: () => goToHashLink(account?.toolsObj?.manageCloudNavURL),
    },
    {
      title: account?.toolsObj?.myStandards,
      onClick: () => goToHashLink('/insightweb/search#demoCompanyStandards'),
    },
    {
      title:
        account?.toolsObj?.myStandardsNew === 'Company Standards'
          ? 'companyStandards'
          : account?.toolsObj?.myStandardsNew,
      onClick: () => goToHashLink('/insightweb/companyStandards'),
    },
    {
      title: account?.toolsObj?.demoStandards ? 'companyStandards' : '',
      onClick: () => goToHashLink('/insightweb/search#demoCompanyStandards'),
    },
    {
      title:
        account?.toolsObj?.demoStandardsNew === 'Company Standards'
          ? 'companyStandards'
          : account?.toolsObj?.demoStandardsNew,
      onClick: () => goToHashLink('/insightweb/companyStandards'),
    },
    {
      title: account?.toolsObj?.licenseAdvisor
        ? 'Enterprise License Dashboard'
        : '',
      onClick: () => goToHashLink(account?.toolsObj?.licenseAdvisorNavURL),
    },
    {
      title: account?.toolsObj?.emeaLicenseAdvisor
        ? 'Enterprise License Dashboard EMEA'
        : '',
      onClick: () => goToHashLink(account?.toolsObj?.emeaLicenseAdvisorNavURL),
    },
    {
      title: account?.toolsObj?.renewalManager
        ? enableRenewalsHub ? 'Renewals Hub':'Renewals & warranty manager'
        : '',
        onClick: () => goToHashLink(enableRenewalsHub ? account?.toolsObj?.renewalsHubURL:account?.toolsObj?.renewalsNavURL),
    },
    {
      title: account?.toolsObj?.softwareContracts
        ? 'softwareLicensingAgreements'
        : '',
      onClick: () => goToHashLink('/insightweb/softwareLicensing'),
    },
    {
      title: account?.toolsObj?.savedCarts ? 'savedCarts' : '',
      onClick: () => goToHashLink('/insightweb/manageCart'),
    },
    {
      title: account?.toolsObj?.serviceNow ? 'Licensing Desk' : '',
      onClick: () => goToHashLink(account?.toolsObj?.serviceNowNavURL),
    },
  ]

  return toolsObject.map((tools) => {
    if (tools?.title) {
      return (
        <li>
          <Button {...tools}>{t(tools?.title)}</Button>
        </li>
      )
    }
  })
}
