import React, { useContext, useEffect, useState } from 'react'

import Header from '@insight/toolkit-react/lib/Header/Header'

import { getHomeURL, t } from 'api'

import IAHeaderContext from '../../../context/IAHeaderContext'
import { MASTHEAD_ICON_TITLES_HOMEPAGE } from '../../../api/common/constants'

export default function InsightLogo() {
  const [homeURL, setHomeURL] = useState('#')
  const { headerInfo } = useContext(IAHeaderContext)
  const isPublicSector = headerInfo.isSEWPUser || headerInfo.isIPSUser
  const title = MASTHEAD_ICON_TITLES_HOMEPAGE

  const fetchHomeUrl = async (headerInfo) => {
    const url = await getHomeURL(headerInfo)
    setHomeURL(url)
  }

  useEffect(() => {
    fetchHomeUrl(headerInfo)
  }, [headerInfo])

  return (
    <Header.Logo
      href={homeURL}
      isPublicSector={isPublicSector}
      title={t(title)}
    />
  )
}
