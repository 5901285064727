import React, { useContext, useEffect, useState } from 'react'

import { getHomeURL } from 'api'

import Button from '@insight/toolkit-react/lib/Button/Button'
import IAHeaderContext from '../../../context/IAHeaderContext'

export default function ITSLogo() {
  const { headerInfo } = useContext(IAHeaderContext)
  const [homeURL, setHomeURL] = useState('#')

  const fetchHomeUrl = async () => {
    const url = await getHomeURL(headerInfo)
    setHomeURL(url)
  }

  useEffect(() => {
    fetchHomeUrl(headerInfo)
  }, [headerInfo])

  return (
    <div className="c-header-logo">
      <Button color="none" className="c-header-logo__btn" href={homeURL}>
        <svg
          className="c-logo c-logo--its"
          x="0px"
          y="0px"
          viewBox="0 0 202.2 59.8"
        >
          <switch>
            <g>
              <g id="Layer_1">
                <g>
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M48.5,54.2c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C53.7,54.2,51.1,54.2,48.5,54.2z"
                  />
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M39.7,54.1c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C44.9,54.1,42.3,54.1,39.7,54.1z"
                  />
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M30.9,54.2c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C36.1,54.2,33.5,54.2,30.9,54.2z"
                  />
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M22.1,54.2c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C27.3,54.2,24.7,54.2,22.1,54.2z"
                  />
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M13.3,54.1c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C18.5,54.1,15.9,54.1,13.3,54.1z"
                  />
                  <path
                    style={{ fill: '#1A3767' }}
                    d="M4.5,54.2c0-2.6,0-5.2,0-7.8c2.6,0,5.1,0,7.8,0c0,1.3,0,2.6,0,3.9c0,1.3,0,2.6-0.1,4 C9.7,54.2,7.1,54.2,4.5,54.2z"
                  />
                </g>
                <rect
                  x="60.4"
                  y="35.1"
                  style={{ fill: '#1A3767' }}
                  width="128.8"
                  height="19"
                />
                <g>
                  <path
                    style={{ fill: '#FBFBFC' }}
                    d="M90.3,46l1-0.1c0,0.4,0.2,0.7,0.3,1c0.2,0.3,0.4,0.5,0.8,0.6c0.4,0.2,0.8,0.2,1.2,0.2      c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.2-0.4,0.2-0.7c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.4-0.3-0.7-0.5      c-0.2-0.1-0.7-0.2-1.4-0.4c-0.7-0.2-1.3-0.3-1.5-0.5c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.4,0.1-0.8,0.3-1.1      c0.2-0.3,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.8,0.5,1,0.8c0.2,0.4,0.4,0.8,0.4,1.2l-1,0.1      c-0.1-0.5-0.2-0.9-0.6-1.1c-0.3-0.3-0.8-0.4-1.4-0.4c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.8c0,0.3,0.1,0.5,0.3,0.7      c0.2,0.2,0.7,0.4,1.6,0.6c0.9,0.2,1.5,0.4,1.8,0.5c0.5,0.2,0.8,0.5,1,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.4,1.2      c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1,0.3-1.5,0.3c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-0.9-0.5-1.2-0.9C90.5,47,90.3,46.5,90.3,46z      "
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#FBFBFC' }}
                    d="M177.7,46l1-0.1c0,0.4,0.2,0.7,0.3,1c0.2,0.3,0.4,0.5,0.8,0.6c0.4,0.2,0.8,0.2,1.2,0.2      c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.2-0.4,0.2-0.7c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.4-0.3-0.7-0.5      c-0.2-0.1-0.7-0.2-1.4-0.4c-0.7-0.2-1.3-0.3-1.5-0.5c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.4,0.1-0.8,0.3-1.1      c0.2-0.3,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.8,0.5,1,0.8c0.2,0.4,0.4,0.8,0.4,1.2l-1,0.1      c-0.1-0.5-0.2-0.9-0.6-1.1c-0.3-0.3-0.8-0.4-1.4-0.4c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.8c0,0.3,0.1,0.5,0.3,0.7      c0.2,0.2,0.7,0.4,1.6,0.6c0.9,0.2,1.5,0.4,1.8,0.5c0.5,0.2,0.8,0.5,1,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.4,1.2      c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1,0.3-1.5,0.3c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-0.9-0.5-1.2-0.9      C177.9,47,177.7,46.5,177.7,46z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#FBFBFC' }}
                    d="M101.7,44.7c0-1.3,0.3-2.3,1-3c0.7-0.7,1.6-1.1,2.7-1.1c0.7,0,1.4,0.2,1.9,0.5      c0.6,0.3,1,0.8,1.3,1.4c0.3,0.6,0.5,1.3,0.5,2.1c0,0.8-0.2,1.5-0.5,2.1c-0.3,0.6-0.8,1.1-1.3,1.4c-0.6,0.3-1.2,0.5-1.9,0.5      c-0.7,0-1.4-0.2-2-0.5c-0.6-0.4-1-0.8-1.3-1.4C101.9,46,101.7,45.4,101.7,44.7z M102.8,44.7c0,0.9,0.3,1.7,0.8,2.2      c0.5,0.5,1.1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.7-1.3,0.7-2.3c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.5-0.5-0.8-0.9-1.1      c-0.4-0.3-0.9-0.4-1.4-0.4c-0.7,0-1.4,0.2-1.9,0.7C103,42.7,102.8,43.5,102.8,44.7z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#FBFBFC' }}
                    d="M153.6,44.7c0-1.3,0.3-2.3,1-3c0.7-0.7,1.6-1.1,2.7-1.1c0.7,0,1.4,0.2,1.9,0.5      c0.6,0.3,1,0.8,1.3,1.4c0.3,0.6,0.5,1.3,0.5,2.1c0,0.8-0.2,1.5-0.5,2.1c-0.3,0.6-0.8,1.1-1.3,1.4c-0.6,0.3-1.2,0.5-1.9,0.5      c-0.7,0-1.4-0.2-2-0.5c-0.6-0.4-1-0.8-1.3-1.4C153.7,46,153.6,45.4,153.6,44.7z M154.6,44.7c0,0.9,0.3,1.7,0.8,2.2      c0.5,0.5,1.1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.7-1.3,0.7-2.3c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.5-0.5-0.8-0.9-1.1      c-0.4-0.3-0.9-0.4-1.4-0.4c-0.7,0-1.4,0.2-1.9,0.7C154.9,42.7,154.6,43.5,154.6,44.7z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#FBFBFC' }}
                    d="M129.6,40.6h1v4.6c0,0.8-0.1,1.4-0.3,1.9c-0.2,0.5-0.5,0.8-1,1.1c-0.5,0.3-1.1,0.4-1.8,0.4      c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.3-0.8-0.6-1-1.1c-0.2-0.5-0.3-1.1-0.3-2v-4.6h1v4.6c0,0.7,0.1,1.2,0.2,1.5      c0.1,0.3,0.3,0.6,0.6,0.8c0.3,0.2,0.7,0.3,1.1,0.3c0.8,0,1.3-0.2,1.6-0.5c0.3-0.3,0.5-1,0.5-2V40.6z"
                  />
                </g>
                <path
                  style={{ fill: '#FBFBFC' }}
                  d="M166.3,48.6v-8h1.1l4.1,6.3v-6.3h1v8h-1.1l-4.1-6.3v6.3H166.3z"
                />
                <rect
                  x="66.6"
                  y="40.6"
                  style={{ fill: '#FBFBFC' }}
                  width="1"
                  height="8"
                />
                <path
                  style={{ fill: '#FBFBFC' }}
                  d="M75.3,41.6c-0.8,0-2.6,0-2.6,0l0-1l6.4,0v1c0,0-2.1,0-2.5,0c0,0.2,0,7,0,7l-1.2,0     C75.3,48.6,75.3,43.8,75.3,41.6z"
                />
                <path
                  style={{ fill: '#FBFBFC' }}
                  d="M115.6,47.6c0,0,3.8,0,3.8,0l0,1c0,0-3.5,0-5.1,0c0-1,0-6.5,0-8c0,0,1.3,0,1.3,0     C115.6,42,115.6,47.6,115.6,47.6z"
                />
                <rect
                  x="147"
                  y="40.6"
                  style={{ fill: '#FDFDFE' }}
                  width="1"
                  height="8"
                />
                <path
                  style={{ fill: '#FBFBFC' }}
                  d="M138.1,41.6c-0.8,0-2.6,0-2.6,0l0-1l6.4,0v1c0,0-2.1,0-2.5,0c0,0.2,0,7,0,7l-1.2,0     C138.1,48.6,138.1,43.8,138.1,41.6z"
                />
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M56.3,45.4h-7.8v-7.8h7.8V45.4z M49.1,44.8h6.6v-6.7h-6.6V44.8z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M47.5,45.3h-7.8v-7.8h7.8V45.3z M40.3,44.8h6.6v-6.7h-6.6V44.8z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M38.7,45.4h-7.8v-7.8h7.8V45.4z M31.5,44.8h6.6v-6.7h-6.6V44.8z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M29.9,45.4h-7.8v-7.8h7.8V45.4z M22.7,44.8h6.6v-6.7h-6.6V44.8z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M21.1,45.3h-7.8v-7.8h7.8V45.3z M13.9,44.8h6.6v-6.7h-6.6V44.8z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M56.3,36.6h-7.8v-7.8h7.8V36.6z M49.1,36h6.6v-6.7h-6.6V36z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M47.5,36.5h-7.8v-7.8h7.8V36.5z M40.3,36h6.6v-6.7h-6.6V36z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M38.7,36.6h-7.8v-7.8h7.8V36.6z M31.5,36h6.6v-6.7h-6.6V36z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M29.9,36.6h-7.8v-7.8h7.8V36.6z M22.7,36h6.6v-6.7h-6.6V36z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M56.3,27.9h-7.8v-7.8h7.8V27.9z M49.1,27.4h6.6v-6.7h-6.6V27.4z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M47.5,27.9h-7.8v-7.8h7.8V27.9z M40.3,27.3h6.6v-6.7h-6.6V27.3z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M38.7,28h-7.8v-7.8h7.8V28z M31.5,27.4h6.6v-6.7h-6.6V27.4z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M56.3,19.3h-7.8v-7.8h7.8V19.3z M49.1,18.7h6.6V12h-6.6V18.7z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M56.3,10.5h-7.8V2.7h7.8V10.5z M49.1,10h6.6V3.3h-6.6V10z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M47.5,19.3h-7.8v-7.8h7.8V19.3z M40.3,18.7h6.6V12h-6.6V18.7z"
                  />
                </g>
                <line
                  style={{ fill: '#39FF37' }}
                  x1="49.6"
                  y1="37"
                  x2="49.4"
                  y2="37"
                />
              </g>
              <g id="Layer_2">
                <g>
                  <path
                    style={{ fill: '#6C6A67' }}
                    d="M193.3,14.8c0.4,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,1,1c0.2,0.4,0.3,0.9,0.3,1.3      c0,0.4-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-1,1c-0.4,0.2-0.8,0.3-1.3,0.3c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.6-1-1      c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.5,0.1-0.9,0.4-1.3c0.2-0.4,0.6-0.7,1-1C192.5,14.9,192.9,14.8,193.3,14.8z M193.3,15.2      c-0.4,0-0.7,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.7,0.3,1.1c0.2,0.3,0.5,0.6,0.8,0.8      c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1.1-0.3c0.3-0.2,0.6-0.5,0.8-0.8c0.2-0.3,0.3-0.7,0.3-1.1c0-0.4-0.1-0.7-0.3-1.1      c-0.2-0.3-0.5-0.6-0.8-0.8C194,15.3,193.7,15.2,193.3,15.2z M192.2,18.8V16h1c0.3,0,0.6,0,0.7,0.1c0.1,0.1,0.3,0.1,0.4,0.3      c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.2-0.6,0.3c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.3,0.3,0.4,0.6      l0.3,0.5H194l-0.2-0.4c-0.2-0.3-0.4-0.6-0.5-0.7c-0.1-0.1-0.2-0.1-0.4-0.1h-0.3v1.2H192.2z M192.6,17.2h0.5c0.3,0,0.4,0,0.5-0.1      c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.5,0h-0.5V17.2z"
                  />
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 175.7321 29.6477)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    E
                  </text>
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 60.6218 29.6496)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    E
                  </text>
                </g>
                <text
                  transform="matrix(1 0 0 1 76.9064 29.6496)"
                  style={{
                    fill: '#1A3767',
                    fontFamily: 'TimesNewRomanPS-BoldMT',
                    fontSize: '22.08px',
                  }}
                >
                  N
                </text>
                <g>
                  <text
                    transform="matrix(1 0 0 1 100.0368 29.6721)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    P
                  </text>
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 114.9977 29.6477)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    O
                  </text>
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 133.0759 29.6477)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    I
                  </text>
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 142.8571 29.6477)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    N
                  </text>
                </g>
                <g>
                  <text
                    transform="matrix(1 0 0 1 159.7048 29.6477)"
                    style={{
                      fill: '#1A3767',
                      fontFamily: 'TimesNewRomanPS-BoldMT',
                      fontSize: '22.08px',
                    }}
                  >
                    T
                  </text>
                </g>
              </g>
            </g>
          </switch>
        </svg>
      </Button>
    </div>
  )
}
