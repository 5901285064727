export const MAX_COLUMNS_IN_ROW = 4;
const SECTION_NAMES = {
  FIRST_SECTION: 'section-1',
  SECOND_SECTION: 'section-2',
  THIRD_SECTION: 'section-3',
  FOURTH_SECTION: 'section-4',
}

// Get Sections using array indexes
const getSectionsUsingIndex = (submenuList) => {
  const lengthOfSubMenuItems = submenuList ? submenuList.length : 0

  const firstSection = submenuList ? submenuList.slice(0, 4) : []
  const secondSection = submenuList ? submenuList[4] : {}
  let thirdSection;
  let viewAll;
  if(lengthOfSubMenuItems > 7) {
    thirdSection = submenuList ? submenuList.slice(5, lengthOfSubMenuItems-1) : []
    viewAll = submenuList ? submenuList[lengthOfSubMenuItems-1] : {}
    // last Item in array is expected as View all link
  } else {
    thirdSection = submenuList ? submenuList[5] : {}
    viewAll = submenuList ? submenuList[6] : {}
  }

  return {
    firstSection,
    secondSection,
    thirdSection,
    viewAll,
  }
}

// Get Sections using 'name' in nodes
const getSectionsUsingName = (submenuList) => {
  const subMenuListNotEmpty = submenuList?.length > 0;
  let firstSection = null, secondSection = null, thirdSection = null, viewAll = null;
  if (subMenuListNotEmpty) {
    // section 1
    firstSection = submenuList?.find?.((item) => item?.name === SECTION_NAMES.FIRST_SECTION)?.nodes?.slice?.(0, MAX_COLUMNS_IN_ROW);
    // section 2
    secondSection = submenuList?.find?.((item) => item?.name === SECTION_NAMES.SECOND_SECTION)?.nodes?.[0];
    // section 3
    const thirdSectionNodes = submenuList?.find?.((item) => item?.name === SECTION_NAMES.THIRD_SECTION)?.nodes;
    thirdSection = thirdSectionNodes?.length > 1 ? thirdSectionNodes : thirdSectionNodes?.[0];
    // section 4
    viewAll = submenuList?.find?.((item) => item?.name === SECTION_NAMES.FOURTH_SECTION)?.nodes?.[0];
  }

  return {
    firstSection,
    secondSection,
    thirdSection,
    viewAll,
  }
}

export const getSections = (submenuList) => {
  const sections = getSectionsUsingName(submenuList);
  const sectionDataExist = sections?.firstSection || sections?.secondSection || sections?.thirdSection || sections?.viewAll; 
  return sectionDataExist ? sections : getSectionsUsingIndex(submenuList); 
}

export const getColumns = (nodes) => {
  return (nodes?.length > MAX_COLUMNS_IN_ROW) ? 1 : Math.floor(MAX_COLUMNS_IN_ROW / nodes?.length);
}