import { getWebGroups, getSEWPHomeURL } from 'api'

const isWelcomePageDisabled = (userInformation, webGroupsResponse) => {
  const currentWebGroupId = userInformation?.webGroup?.id
  const currentWebGroup = webGroupsResponse?.results?.find?.(
    (webGroup) => webGroup.id === currentWebGroupId
  )
  return currentWebGroup?.welcomePageDisabled ?? true
}

const fetchHomeUrl = async (userInformation) => {
  const webGroupsResponse = await getWebGroups()
  const welcomePageDisabled = isWelcomePageDisabled(
    userInformation,
    webGroupsResponse
  )
  return welcomePageDisabled ? '/' : '/insightweb/welcome'
}

const fetchHomeUrlLoggedOut = (isSEWPUser) => {
  return isSEWPUser ? getSEWPHomeURL() : '/'
}

export default async function getHomeURL({
  userInformation,
  isLoggedIn,
  isSEWPUser,
}) {
  return isLoggedIn
    ? fetchHomeUrl(userInformation)
    : fetchHomeUrlLoggedOut(isSEWPUser)
}
