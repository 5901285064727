import React from 'react'

import { t } from '@insight/toolkit-utils'
import { Button } from '@insight/toolkit-react'

export default function Order(props) {
  const { account, isEMEA, locale, goToHashLink } = props
  const ordersObject = [
    {
      title: account?.ordersObj?.quoteHistory ? 'quoteHistory' : '',
      onClick: () => goToHashLink('/insightweb/quoteHistory'),
    },
    {
      title: account?.ordersObj?.requisitionHistory ? 'reqHistory' : '',
      onClick: () => goToHashLink('/insightweb/ar/reqHistory'),
    },
    {
      title: account?.ordersObj?.newRequest ? 'createNewRequest' : '',
      onClick: () =>
        goToHashLink(
          '/insightweb/newRequest?client=${account?.ordersObj?.newRequest}'
        ),
    },
    {
      title: account?.ordersObj?.orderTracking ? 'orderHistory' : '',
      onClick: () => goToHashLink('/insightweb/orderHistory'),
    },
    {
      title: account?.ordersObj?.invoiceHistory ? 'invoiceHistory' : '',
      onClick: () => goToHashLink('/insightweb/invoiceHistory'),
    },
    {
      title: account?.ordersObj?.rma ? 'rmaRequest' : '',
      onClick: () =>
        goToHashLink(
          isEMEA
            ? `/${locale}/knowledge-base/policies/trading-terms-and-conditions.html`
            : `/${locale}/help/return-policy.html`
        ),
    },
  ]

  return ordersObject.map((orders) => {
    if (orders?.title) {
      return (
        <li>
          <Button {...orders}>{t(orders.title)}</Button>
        </li>
      )
    }
  })
}
