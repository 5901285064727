import axios from 'axios';

export default function getRequesterGroupDetails() {
    return axios.get("/insightweb/ar/getRequestorGroup")
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching Requestor Group details:', error);
            throw error;
        });
}