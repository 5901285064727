import React, { useContext, useEffect, useState, useRef } from 'react'

import { getCurrentLocale } from '@insight/toolkit-utils'
import getMenuDetails from '../../api/us/getMenuDetails'
import LeftNavHeader from './LeftNavHeader'
import MenuItems from './MenuItems'
import HeaderContext from '@insight/toolkit-react/lib/Header/HeaderContext'
import useOutsideClick from '@insight/toolkit-react/lib/Header/useOutsideClick'
export default function LeftNavigation(props) {
  const { userInformation } = props
  const locale = getCurrentLocale('insight_current_locale', 'insight_locale')
  const [account, setAccountTools] = useState()
  const [isAccountLoaded, setIsAccountLoaded] = useState(false)
  const { activeFlyout, setActiveFlyout, activeMegaMenu } =
    useContext(HeaderContext)

  const isOpen = activeFlyout && !activeMegaMenu

  const closeMenu = () => {
    setActiveFlyout(false)
  }

  if (activeMegaMenu) {
    closeMenu()
  }

  const getAccountTools = async () => {
    const responseData = await getMenuDetails()
    setAccountTools(responseData?.data)
    setIsAccountLoaded(true)
  }

  useEffect(() => {
    getAccountTools()
  }, [])

  const leftNavRef = useRef(null)

  /**
   * Close left nav when the user click outside, or tabs outside
   *
   * Note: Because the button to open the mobile search bar is outside of the
   * search bar itself, useOutsideClick has a strange effect: clicking on the
   * button triggers the outside click behaviour, closing the menu, then the
   * click handler of the button determines that the menu should be reopened.
   * Using setTimeout this way, whilst not ideal, forces the correct order of
   * the handlers to acheive the desired effect. This will have to suffice
   * until we have time to look into how useOutsideClick could be made robust
   * enough to cope. (TODO)
   */
  useOutsideClick(
    () => {
      if (isOpen) {
        setTimeout(() => closeMenu(), 0)
      }
    },
    { ref: leftNavRef }
  )

  return (
    <div
      className={`c-sidebar-nav flyout ${isOpen ? 'open' : ''}`}
      aria-hidden={isOpen}
      ref={leftNavRef}
    >
      <div className="c-sidebar-nav-content">
        <LeftNavHeader
          account={account}
          userInformation={userInformation}
          handleClick={() => closeMenu()}
        />
        <MenuItems
          account={account}
          locale={locale}
          userInformation={userInformation}
          handleClick={() => closeMenu()}
        />
      </div>
    </div>
  )
}

LeftNavigation.defaultProps = {}
