import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@insight/toolkit-react/lib/Button/Button'

import Submenu from './Submenu'
import SubmenuItems from './SubmenuItems'
import FadeIn from './FadeIn'
import { MAX_COLUMNS_IN_ROW, getColumns, getSections } from '../../libs/whatWeDoMenuHelpers'

export default function WhatWeDo({ isMobile, submenuList }) {
  const [activeSubMenu, setActiveSubMenu] = useState(null)

  // In IPS scenario we will receive more submenu items than standard submenu items (7)
  const {
    firstSection,
    secondSection,
    thirdSection,
    viewAll,
  } = getSections(submenuList);

  const firstSectionLength = firstSection?.length;
  const firstSectionClass = `u-1/${firstSectionLength > MAX_COLUMNS_IN_ROW ? MAX_COLUMNS_IN_ROW : firstSectionLength}@desktop`;
  const thirdSectionLength = thirdSection?.length;
  const thirdSectionClass = `u-1/${thirdSectionLength > MAX_COLUMNS_IN_ROW ? MAX_COLUMNS_IN_ROW : thirdSectionLength}@desktop`;

  const getSubMenu = (submenuNode, columns, delay) => {
    return (
      <Submenu
        color={submenuNode.backgroundColor}
        columns={columns}
        delay={delay}
        hideViewAll
        id={submenuNode.id}
        isOpen={activeSubMenu === submenuNode.id}
        onClose={() => setActiveSubMenu(null)}
        onOpen={() => setActiveSubMenu(submenuNode.id)}
        tagline={submenuNode.description}
        title={submenuNode.title}
        {...(submenuNode.href ? { href: submenuNode.href } : {})}
      >
        <SubmenuItems isMobile={isMobile} items={submenuNode.nodes} />
      </Submenu>
    )
  }

  return (
    <div className='what-we-do-menu'>
      {/* First Section */}
      <div className="c-header-mega-menu__section">
        <div className="o-wrapper">
          <div className="o-grid  o-grid--gutters">
            {firstSection?.map((submenuNode, index) => (
              <div key={submenuNode.id} className={`o-grid__item  u-1/1 ${firstSectionClass}`}>
                {getSubMenu(submenuNode, getColumns(firstSection), index)}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Second Section */}
      {secondSection && (
        <div className="c-header-mega-menu__section  c-header-mega-menu__section--highlight">
          <div className="o-wrapper">
            <div className="o-grid  o-grid--justify-between  o-grid--bottom">
              <div className="o-grid__item  u-1/1  u-width-shrink@desktop">
                {getSubMenu(secondSection, 3, 4)}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Third Section */}
      {thirdSection && (
        <div className="c-header-mega-menu__section">
          <div className="o-wrapper">
            {Array.isArray(thirdSection) ? (
              <div className="o-grid  o-grid--justify-between">
                {thirdSection?.map((submenuNode, index) => (
                  <div key={submenuNode.id} className={`o-grid__item  u-1/1 ${thirdSectionClass}`}>
                    {getSubMenu(submenuNode, getColumns(thirdSection), index)}
                  </div>
                ))}
                {/* Fourth Section */}
                {renderViewAll(viewAll)}
              </div>
            ) : (
              <div className="o-grid  o-grid--justify-between o-grid--bottom">
                <div className="o-grid__item  u-1/1  u-width-shrink@desktop">
                  {getSubMenu(thirdSection, 3, 5)}
                </div>
                {/* Fourth Section */}
                {renderViewAll(viewAll)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function renderViewAll(viewAll) {
  return viewAll && (
    <div className="o-grid__item  u-1/1  u-width-shrink@desktop">
      <FadeIn delay={5}>
        <Button
          className="c-header-nav__link  u-margin-top u-margin-bot-small  u-show@desktop"
          color="primary"
          href={viewAll.href}
          size="small"
        >
          {viewAll.title}
        </Button>
        <Button
          className="c-header-nav__link  c-header-nav__link--view-all  u-hide@desktop"
          color="subtle"
          href={viewAll.href}
        >
          {viewAll.title}
        </Button>
      </FadeIn>
    </div>
  )
}

WhatWeDo.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  submenuList: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      description: PropTypes.string,
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}
